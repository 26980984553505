import type { UIRouter } from '@uirouter/core';

import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { defaultsPlugin } from '../defaults';
import { commonServicesPlugin } from '../common-services';
import { editorLocationPlugin } from '../editor-location';
import { editorNavigationPlugin } from '../editor-navigation';

export function editorPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    router.plugin(commonServicesPlugin());
    router.plugin(defaultsPlugin(flowApi));
    router.plugin(editorLocationPlugin(flowApi));
    router.plugin(editorNavigationPlugin(flowApi));

    return {
      name: 'WixUIRouterEditor',
    };
  };
}
