import type { ILocation, NavOptions } from '@wix/yoshi-flow-editor';

import { LocationService as BaseLocationService } from '../../core';

export class LocationService extends BaseLocationService {
  constructor(protected location: ILocation) {
    super(location);

    this.location.onChange(this.notifyListeners);
  }

  set(url: string, options?: NavOptions): void {
    this.location.to?.(url, options);
  }
}
