import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { UIRouter, StateObject, Rejection } from '@uirouter/core';

export function defaultsPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    router.stateService.defaultErrorHandler(handleError);
    router.stateRegistry.decorator('resolvePolicy', resolvePolicyDecorator);

    return {
      name: 'WixUIRouterDefaults',
    };
  };

  function resolvePolicyDecorator(state: StateObject) {
    const { resolvePolicy } = state;
    const { isSSR } = flowApi.environment;

    return {
      when: resolvePolicy?.when || 'EAGER',
      async: resolvePolicy?.async || isSSR ? 'WAIT' : 'NOWAIT',
    };
  }

  function handleError(error: Rejection) {
    const trackedError = Object.assign(
      new Error(error.detail.message ?? 'transitionErrorHandler catch'),
      {
        ...(error?.detail?.isAxiosError && {
          isAxiosError: error?.detail?.isAxiosError,
        }),
        ...(error?.detail?.isWixHttpError && {
          isWixHttpError: error?.detail?.isWixHttpError,
        }),
        ...(error?.detail?.requestId && {
          requestId: error?.detail?.requestId,
        }),
        ...(error?.detail?.response && {
          requestId: error?.detail?.response,
        }),
        ...(error?.detail?.config && { config: error?.detail?.config }),
      },
    );

    trackedError.stack = error?.detail?.stack;

    flowApi.errorMonitor.captureException(trackedError, {
      contexts: {
        transitionErrorDetails: error.detail,
        originalError: {
          transitionErrored: error.toString(),
        },
      },
      tags: {
        reasonMessage: error?.detail?.message,
        reasonStatus: error?.detail?.status,
      },
    });

    console.error(error);
  }
}
