import type { Transition, UIRouter } from '@uirouter/core';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { anotherComponentHookCriteria } from '../../helpers';

export function editorNavigationPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    router.transitionService.onBefore(
      { to: anotherComponentHookCriteria },
      handleNavigation,
    );

    try {
      const params = JSON.parse(
        flowApi.controllerConfig.wixCodeApi.location.query.appSectionParams,
      );
      if (params.state) {
        router.urlService.rules.initial(params);
      }
    } catch {}

    return {
      name: 'WixUIRouterEditorNavigation',
    };
  };

  async function handleNavigation(transition: Transition) {
    const { controllerConfig } = flowApi;
    const { wixCodeApi, compId } = controllerConfig;

    const to = transition.to();

    const section = {
      compId,
      noTransition: true,
      sectionId: to.data.sectionId,
      queryParams: {
        state: to.name,
        params: transition.params('to'),
      },
    };

    wixCodeApi.location.navigateToSection(section);
  }
}
