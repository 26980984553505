import {
  UIRouter,
  MemoryLocationConfig,
  MemoryLocationService,
} from '@uirouter/core';

import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export function editorLocationPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    const configuration = new MemoryLocationConfig();
    const service = new MemoryLocationService(router);

    router.locationConfig = configuration;
    router.locationService = service;

    return {
      name: 'WixUIRouterEditorLocation',
      dispose(router: UIRouter) {
        router.dispose(configuration);
        router.dispose(service);
      },
    };
  };
}
