import { UIRouter } from '@uirouter/core';

import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { LocationConfig } from './LocationConfig';
import { LocationService } from './LocationService';

export function viewerLocationPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    const {
      controllerConfig: { wixCodeApi },
    } = flowApi;

    const configuration = new LocationConfig(wixCodeApi.location);
    const service = new LocationService(wixCodeApi.location);

    router.locationConfig = configuration;
    router.locationService = service;

    return {
      name: 'WixUIRouterViewerLocation',
      dispose(router: UIRouter) {
        router.dispose(configuration);
        router.dispose(service);
      },
    };
  };
}
