import { cloneDeep, merge, omit } from 'lodash';
import { UIRouter } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';

import type {
  I$WWrapper,
  IWidgetController,
} from '@wix/native-components-infra/dist/src/types/types';

import { selectStateDeclarations } from 'store/application/selectors';
import { injectWarmupData } from 'store/actions';
import { ROUTER_WARMUP_DATA_KEY, STORE_WARMUP_DATA_KEY } from 'store/constants';
import type { IRootStore } from 'store/types';
import { createStore } from 'store/index';

import type { Resolvables } from 'router/helpers';
import { editorPlugin } from 'router/plugins/editor';
import { viewerPlugin } from 'router/plugins/viewer';

import { ApplicationVM } from './vm/application.vm';
import { CentralFeedVM } from './vm/central-feed.vm';
import { CommentsVM } from './vm/comments.vm';
import { EventsVM } from './vm/events.vm';
import { FeedVM } from './vm/feed.vm';
import { FilesVM } from './vm/files.vm';
import { GroupVM } from './vm/group.vm';
import { GroupsVM } from './vm/groups.vm';
import { MediaVM } from './vm/media.vm';
import { MembersVM } from './vm/members.vm';
import { RouterVM } from './vm/router.vm';
import { TopicsVM } from './vm/topics.vm';
import { GroupRequestsVM } from './vm/group-requests.vm';
import { PricingPlansVM } from './vm/pricing-plans.vm';

import { migrate as migrateSettings } from './settings/migration';
import { ESettingsPanelEvent } from './vm/constants';
import type { IVM, SettingsPanelEventHandler } from './vm/types';

export class WixGroupsController implements IWidgetController {
  public store: IRootStore;
  public router: UIRouter;
  public vm: IVM;

  protected settingsEvents: ReturnType<typeof createEventHandler>;

  constructor(public params: ControllerParams) {
    const { flowAPI } = params;
    const { config } = flowAPI.controllerConfig;

    this.store = createStore(flowAPI);
    this.router = new UIRouter();

    this.router.plugin(
      flowAPI.environment.isViewer
        ? viewerPlugin(flowAPI)
        : editorPlugin(flowAPI),
    );

    const commentsVM = CommentsVM(this.params, this.store);

    this.vm = merge(
      commentsVM,
      ApplicationVM(this.params, this.store, this.router),
      CentralFeedVM(this.params, commentsVM, this.store),
      CommentsVM(this.params, this.store),
      EventsVM(this.params, this.store),
      FeedVM(this.params, commentsVM, this.store),
      FilesVM(this.params, this.store),
      GroupVM(this.params, this.store, this.router),
      GroupsVM(this.params, this.store),
      MediaVM(this.params, this.store),
      MembersVM(this.params, this.store),
      RouterVM(this.params, this.store, this.router),
      TopicsVM(this.params, this.store),
      GroupRequestsVM(this.params, this.store),
      PricingPlansVM(this.params, this.store),
    );

    this.settingsEvents = createEventHandler<SettingsPanelEventHandler>(
      config.publicData.COMPONENT,
    );

    this.settingsEvents.on(
      ESettingsPanelEvent.RouteChange,
      this.handleRouterStateChangeRequest.bind(this),
    );

    this.settingsEvents.onReset(this.handleSettingsReset.bind(this));

    this.setupStates();

    flowAPI.bi?.updateDefaults({ userEntry: 'site' });
  }

  setupStates() {
    const { vm } = this;
    const states = selectStateDeclarations(this.store.getState());

    this.router.stateRegistry.register({
      ...states['social-groups'],
      resolve: [
        {
          token: 'user',
          policy: { async: 'WAIT' },
          async resolveFn() {
            const user = await vm.application$.fetchUserProfile().unwrap();

            return user;
          },
        },
      ],
    });

    for (const [key, state] of Object.entries(states)) {
      if (key !== 'social-groups') {
        this.router.stateRegistry.register(cloneDeep(state));
      }
    }
  }

  async pageReady() {
    await this.resolve();

    this.setInitialProps();

    this.router.urlService.listen();
    this.store.subscribe(this.updateStore.bind(this));
  }

  updateConfig($w: I$WWrapper, updatedConfig: { [key: string]: any }) {
    this.settingsEvents.notify(updatedConfig.publicData.COMPONENT || {});
  }

  onBeforeUnLoad() {
    this.vm._.comments.dispose();
    this.router.dispose();
  }

  protected async resolve() {
    const { flowAPI } = this.params;
    const { wixCodeApi } = flowAPI.controllerConfig;
    const { warmupData } = wixCodeApi.window;
    const { isSSR } = flowAPI.environment;

    if (!isSSR) {
      const state = warmupData.get(STORE_WARMUP_DATA_KEY);
      const resolvables = warmupData.get(ROUTER_WARMUP_DATA_KEY);

      this.store.dispatch(injectWarmupData(state));
      await this.handleTransition(resolvables).catch(() => {});

      return;
    }

    const resolvables = await this.handleTransition().catch(() => ({}));

    warmupData.set(ROUTER_WARMUP_DATA_KEY, resolvables);
    warmupData.set(STORE_WARMUP_DATA_KEY, this.store.getState());
  }

  public handleTransition(resolvables?: Resolvables): Promise<Resolvables> {
    return Promise.resolve({});
  }

  public handleSettingsReset() {}

  public handleRouterStateChangeRequest(state: string) {
    this.router.stateService.go(state);
  }

  protected updateStore() {
    const { setProps } = this.params.flowAPI.controllerConfig;

    setProps({
      store: this.store.getState(),
    });
  }

  public setInitialProps() {
    const { isViewer } = this.params.flowAPI.environment;
    const { setProps, config } = this.params.flowAPI.controllerConfig;

    setProps({
      ...omit(this.vm, '_'),
      fitToContentHeight: true,
      store: this.store.getState(),
    });

    if (isViewer) {
      setProps(
        this.params.dangerousStylesOverride(
          migrateSettings(config.style.styleParams, config.publicData),
        ),
      );
    }

    this.vm._.comments.bind();
  }
}
