import type { UIRouter } from '@uirouter/core';

import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { defaultsPlugin } from '../defaults';
import { commonServicesPlugin } from '../common-services';
import { viewerLocationPlugin } from '../viewer-location';
import { viewerNavigationPlugin } from '../viewer-navigation';

export function viewerPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    router.plugin(commonServicesPlugin());
    router.plugin(defaultsPlugin(flowApi));
    router.plugin(viewerLocationPlugin(flowApi));
    router.plugin(viewerNavigationPlugin(flowApi));

    return {
      name: 'WixUIRouterViewer',
    };
  };
}
