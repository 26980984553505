import { pick } from 'lodash';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type {
  RawParams,
  Transition,
  TransitionOptions,
  UIRouter,
} from '@uirouter/core';

import type { IRootStore } from 'store/index';
import * as application from 'store/application';
import { sameComponentHookCriteria } from 'router/helpers';

import { getAppData } from './helpers';

export function RouterVM(
  params: ControllerParams,
  store: IRootStore,
  router: UIRouter,
) {
  const {
    controllerConfig: { wixCodeApi },
  } = params;
  const { routes } = getAppData(params);

  store.dispatch(application.actions.setupRouterStates(routes));
  store.dispatch(
    application.actions.updateLocation(
      pick(wixCodeApi.location, [
        'baseUrl',
        'path',
        'protocol',
        'query',
        'url',
      ]),
    ),
  );

  router.transitionService.onBefore(
    { to: sameComponentHookCriteria },
    handleStateUpdate,
  );

  router.transitionService.onError(
    { to: sameComponentHookCriteria },
    handleStateUpdate,
  );

  return {
    router$: {
      go(state: string, params?: RawParams, options?: TransitionOptions) {
        router.stateService.go(state, params, options);
      },
      reload(state?: string) {
        router.stateService.reload(state);
      },
    },
  };

  function handleStateUpdate(transition: Transition) {
    store.dispatch(
      application.actions.updateRouter({
        params: transition.params('to'),
        state: transition.to().name as string,
      }),
    );
  }
}

export type IRouterVM = ReturnType<typeof RouterVM>;
